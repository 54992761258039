import React from 'react'
import {
  CFImage,
  CFLink,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { yelp, facebook, instagram, zomato } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView mv="30px" column justifyStart>
          <CFView row center black bold>
            <CFLink
              target="_blank"
              href="https://www.facebook.com/Sushi-Giwa-739572306080100/"
            >
              <CFImage
                w="40px"
                pr="10px"
                src={facebook}
                alt="Sushi Giwa Japanese Restaurant Facebook"
              />
            </CFLink>
            <CFLink href="https://www.instagram.com/sushi_giwa/?hl=en">
              <CFImage
                w="40px"
                pr="10px"
                src={instagram}
                alt="Sushi Giwa Japanese Restaurant Instagram"
              />
            </CFLink>
            <CFLink href="https://www.zomato.com/vancouver/sushi-giwa-north-burnaby/menu">
              <CFImage
                w="30px"
                src={zomato}
                alt="Sushi Giwa Japanese Restaurant Instagram"
              />
            </CFLink>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView mb="13px" mt="15px" column justifyStart>
          <CFView row justifyStart alignCenter black bold>
            <CFLink
              target="_blank"
              href="https://www.facebook.com/Sushi-Giwa-739572306080100/"
            >
              <CFImage
                w="40px"
                pr="10px"
                src={facebook}
                alt="Sushi Giwa Japanese Restaurant Facebook"
                hover
              />
            </CFLink>
            <CFLink href="https://www.instagram.com/sushi_giwa/?hl=en">
              <CFImage
                w="40px"
                pr="10px"
                src={instagram}
                alt="Sushi Giwa Japanese Restaurant Instagram"
                hover
              />
            </CFLink>
            <CFLink href="https://www.zomato.com/vancouver/sushi-giwa-north-burnaby/menu">
              <CFImage w="30px" src={zomato} alt="Si Instagram" hover />
            </CFLink>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
