import React from 'react'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { promotions, promotionsMobile } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center mt="35px">
          <CFImage src={promotionsMobile} w="90%" alt="Promotions" />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center maxWidth="1400px" m="auto 0" w="100%">
          <CFImage src={promotions} w="100%" alt="Promotions" />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
